const aboutMe = [
    "I love to travel and explore new food and places. My wife and I have been to Iceland, Mexico, Jamaica, and all over the United States. Some of my hobbies include playing video games and board games, watching movies, working out, going to Renaissance Festival and concerts, making art and doing anything that's creative and fun.",
    "My favorite:",
    "- Video games are God of War, The Last of Us, Resident Evil games and any cooperative games that I can play with my friends.",
    "- Board games are Nemesis, Pandemic, Twighlight Imperium, Cosmic Encounter and many more.",
    "- Movies are The Lord of the Rings, The Matrix, The Dark Knight, Blade and most of the Marvel movies.",
    "- TV shows are The Office, The Mandalorian, The Last of Us, Breaking Bad, and many more.",
    "- Food are sushi and chocolate."
]

export default aboutMe;